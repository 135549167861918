import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PropTypes from 'prop-types';
import CreateEditPoll from 'components/polling/CreateEditPoll';
import ListPoll from 'components/polling/ListPoll';
import PollPresenter from 'components/polling/PollPresenter';
import PollAttendee from 'components/polling/PollAttendee';
import PollAttendeeActive from 'components/polling/PollAttendeeActive';
import {
  setFocusedPollIdAction,
  resetFocusedPollIdAction,
} from 'store/session-polling/sessionPollingActions';
// import useInterval from 'hooks/useInterval';
import { useRunningPoll } from 'utils/polling';

const useStyles = makeStyles((theme) => ({
  polling: {
    padding: `0.75rem ${theme.sidePanel.gutter}`,
    overflow: 'hidden auto',
  },
  backIcon: {
    fontSize: '1rem',
  },
  create: {
    marginBottom: '1rem',
    textAlign: 'right',
  },
  topArea: {
    display: 'flex',
    marginBottom: '1rem',
  },
  noPolls: {
    textAlign: 'center',
    fontSize: '1.25rem',
    marginTop: '5rem',
  },
}));

Polling.propTypes = {
  isSettingsPage: PropTypes.bool,
};

export default function Polling({ isSettingsPage }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const [lookingAtActivePollId, setLookingAtActivePollId] = useState(null);
  const role = useSelector((state) => state.user.role);
  const canManage = role === 'presenter' || role === 'admin';
  const polls = useSelector((state) => state.sessionPolling.polls, deepEqual);
  const activePoll = useRunningPoll();

  // const [forceUpdateTick, setForceUpdateTick] = useState(0);
  // useInterval(() => setForceUpdateTick(forceUpdateTick + 1), 1000);

  const Poll = canManage ? PollPresenter : PollAttendee;
  const focusedPollId = useSelector((state) => state.sessionPolling.focusedPollId);
  const focusedPoll = focusedPollId && polls.find((poll) => poll.pollId === focusedPollId);

  useEffect(() => {
    return () => {
      dispatch(resetFocusedPollIdAction());
    };
  }, []);

  const onCreatedOrEditedPoll = (error) => {
    if (!error) {
      setCreating(false);
      setLookingAtActivePollId(null);
      dispatch(resetFocusedPollIdAction());
    }
  };

  const onClickBack = () => {
    setCreating(false);
    setLookingAtActivePollId(null);
    dispatch(resetFocusedPollIdAction());
  };

  const onClickCreate = () => {
    dispatch(resetFocusedPollIdAction());
    setLookingAtActivePollId(null);
    setCreating(true);
  };

  const onClickSeeResults = (event, pollId) => {
    setLookingAtActivePollId(null);
    dispatch(setFocusedPollIdAction({ focusedPollId: pollId }));
  };

  const renderBody = () => {
    // Attendee: active poll
    if (!canManage) {
      // If we were staring at an active poll that goes inactive, we don't wanna be kicked off of it.
      if (lookingAtActivePollId) {
        const lookingAtActivePoll = polls.find((poll) => poll.pollId === lookingAtActivePollId);
        if (lookingAtActivePoll) {
          return (
            <PollAttendeeActive
              onClickSeeResults={onClickSeeResults}
              setLookingAtActivePollId={setLookingAtActivePollId}
              poll={lookingAtActivePoll}
              onClickBack={onClickBack}
            />
          );
        }
      }

      if (focusedPoll) {
        if (focusedPollId === activePoll?.pollId)
          return (
            <PollAttendeeActive
              onClickSeeResults={onClickSeeResults}
              setLookingAtActivePollId={setLookingAtActivePollId}
              poll={activePoll}
              onClickBack={onClickBack}
            />
          );
        else return <Poll poll={focusedPoll} expanded={true} inSettings={isSettingsPage} />;
      }

      // if (activePoll) {
      //   return (
      //   );
      // }

      return (
        <ListPoll polls={polls} activePollId={activePoll?.pollId} inSettings={isSettingsPage} />
      );
    }

    // Expanded poll
    if (focusedPoll) {
      return <Poll poll={focusedPoll} expanded={true} inSettings={isSettingsPage} />;
    }

    // Presenter: creating
    if (creating)
      return (
        <CreateEditPoll
          isSettingsPage={isSettingsPage}
          onSubmitted={onCreatedOrEditedPoll}
          onClickBack={onClickBack}
        />
      );

    // Otherwise: listing relevant polls
    return <ListPoll polls={polls} activePollId={activePoll?.pollId} inSettings={isSettingsPage} />;
  };

  return (
    <div className={classes.polling}>
      <div className={classes.topArea}>
        {creating || focusedPoll ? (
          <IconButton className={classes.backIcon} onClick={onClickBack}>
            <ArrowBackIosIcon /> Back
          </IconButton>
        ) : (
          <>
            {canManage && !isSettingsPage && (
              <>
                <div style={{ flexGrow: 1 }} />
                <Button onClick={onClickCreate} variant="contained" color="primary">
                  Create Poll
                </Button>
              </>
            )}
          </>
        )}
      </div>

      {renderBody()}
    </div>
  );
}
