import { getSession } from 'api/routes/session';
import { store } from 'App';
import { receiveLoadTestRequest } from 'components/side-panel/components/testing/api';
import { countKeys, historyKeys } from 'constants/analytics';
//import has from 'lodash/has';
import throttle from 'lodash/throttle';
import logger from 'logger';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as aa from 'store/analytics/analyticsActions';
import * as ca from 'store/chime/chimeActions';
import { setFeatureDisabledAction, updatePermissionsAction } from 'store/event/eventActions';
import * as sma from 'store/session-message/sessionMessageActions';
import * as spa from 'store/session-polling/sessionPollingActions';
import * as sqa from 'store/session-question/sessionQuestionActions';
import * as sra from 'store/session-resource/sessionResourceActions';
import * as sa from 'store/session/sessionActions';
import { disconnectUserAction, initializeWebSocketAction } from 'store/web-socket/webSocketActions';
import * as Sentry from '@sentry/browser';

const updateQuestionFunc = ({ questionId, data }) => {
  store.dispatch(sqa.updateQuestionAction({ questionId, data }));
  /*if (has(data, 'voteCount')) {
    store.dispatch(sqa.updateQuestionAction({ questionId, data: { voteCount: data.voteCount } }));
  }
  if (has(data, 'hidden')) {
    store.dispatch(sqa.updateQuestionAction({ questionId, data: { hidden: data.hidden } }));
  }
  if (has(data, 'answer')) {
    store.dispatch(sqa.updateQuestionAction({ questionId, data: { answer: data.answer } }));
  }*/
};

const analyticsUpdateFunc = ({ userId, timestamp, analyticsKey, historyType }) => {
  if (historyKeys.includes(analyticsKey)) {
    store.dispatch(
      aa.updateAnalyticsHistoryObjectAction({
        analyticsKey,
        historyType,
        userId,

        timestamp: parseInt(timestamp),
      })
    );
  } else if (countKeys.includes(analyticsKey)) {
    store.dispatch(
      aa.updateAnalyticsCountObjectAction({
        analyticsKey,
        userId,
        timestamp: parseInt(timestamp),
      })
    );
  }
};

const updateCountFunc = ({ count }) => {
  store.dispatch(sa.setUsersInSessionAction({ count }));
};

const setInGridFunc = ({ userId, timestamp }) => {
  if (timestamp == '0') {
    //eslint-disable-line
    store.dispatch(ca.leaveGridAction({ userId }));
  } else {
    store.dispatch(ca.enterGridAction({ userId, timestamp }));
  }
};

const endSessionFunc = () => {
  store.dispatch(ca.leaveRoomAction({ shouldEndSession: false }));
};

const receiveChatFunc = ({ message }) => {
  //store.dispatch(sma.putMessageAction({ message }));
  store.dispatch(sma.chatMessageAction({ message }));
};

const hostStartedMeetingFunc = () => {
  store.dispatch(ca.hostStartedMeetingAction());
};

const kickUserFunc = () => {
  store.dispatch(ca.leaveRoomAction({ shouldEndSession: false }));
};

const receivePresentersChatFunc = ({ message }) => {
  store.dispatch(sma.chatMessageAction({ message, presentersMessage: true }));
};

const receiveQueueChatFunc = ({ message }) => {
  store.dispatch(sma.chatMessageAction({ message, queueMessage: true }));
};

const loadTestRequestFunc = async ({ type, payload }) => {
  await receiveLoadTestRequest({ type, payload });
};

const localVideoStatusFunc = ({ videoStatus, userId }) => {
  store.dispatch(
    ca.updateRosterAttributeAction({ userId, key: 'videoStatus', value: videoStatus })
  );
};

const loggedInElsewhereFunc = () => {
  // Maybe give them a popup later?
  store.dispatch(ca.leaveRoomAction({ shouldEndSession: false }));
};

const permissionsUpdateGroupFunc = ({ permission }) => {
  let { groupName, eventId, ...permissionToUpdate } = permission; //eslint-disable-line
  store.dispatch(updatePermissionsAction({ groupName, permission: permissionToUpdate }));
};

const receivePutQuestionFunc = ({ question }) => {
  store.dispatch(sqa.putQuestionAction(question));
};

const raiseHandFunc = ({ userId: targetUserId, handIsRaised, name }) => {
  handIsRaised = handIsRaised ?? false;

  const userId = store.getState().user.userId;

  if (userId === targetUserId) {
    store.dispatch(sa.setRaisedHandAction({ handIsRaised }));
  } else {
    store.dispatch(
      ca.updateRosterAttributeAction({
        userId: targetUserId,
        key: 'handIsRaised',
        value: handIsRaised,
      })
    );

    store.dispatch(sa.notifyRaiseHandAction({ handIsRaised, name }));
  }
};

const setIsRecordingFunc = ({ isRecording }) => {
  store.dispatch(sa.setIsRecordingAction({ isRecording }));
};

const removeAllUsersFromGridFunc = () => {
  store.dispatch(sa.removeAllUsersFromGridAction());
};

const selectSpeakerFunc = ({ userId }) => {
  store.dispatch(sa.setSpeakerAction({ userId }));
};

const updateSessionFunc = (payload) => {
  const { session, currentInput } = payload;
  store.dispatch(
    sa.setSessionAction({
      ...session,
      usersInSession: session?.usersInSession?.length || 0,
    })
  );
  store.dispatch(sra.setCurrentInputAction({ currentInput }));
};

const receiveStopUserVideoFunc = () => {
  store.dispatch(ca.toggleVideoAction({ forceLocalOff: true }));
};

const updateResourceStatusFunc = ({ status }) => {
  store.dispatch(sa.setResourceStatusAction({ status }));
};

const studioNextInputFunc = ({ nextInput }) => {
  store.dispatch(sra.setNextInputAction({ nextInput }));
};

const switchInputFunc = ({ showVideo, videoKey, shouldLoop, currentInput }) => {
  store.dispatch(sra.setInputAction({ showVideo, videoKey, shouldLoop, currentInput }));
};

const updateLiveStreamUserFunc = () => {
  //TODO do we still need this it was moved from websocket sagas
  //yield call(updateLiveStreamUserSaga, payload);
};

const receiveMuteFunc = () => {
  store.dispatch(ca.toggleMuteSelfAction({ forceMute: true }));
};

const muteAll = ({ isMuted }) => {
  const role = store.getState().user.role;
  if (role !== 'presenter' && role !== 'admin') {
    //Mutes the current user
    receiveMuteFunc();

    //Ensures they do not start with audio
    store.dispatch(
      ca.togglePreviewAction({
        audioDevice: null,
        setStartWithAudio: true,
        startWithAudioState: false,
      })
    );
  }

  //Updates master mute state
  store.dispatch(sa.setAllMutedAction({ isMuted }));
};

const updateStreamStatusFunc = ({ status }) => {
  store.dispatch(sa.setStreamStatusAction({ status }));
};

const forceLogout = () => {
  // store.dispatch(ca.leaveRoomAction({ shouldEndSession: false, forceLogout: true }));
};

const receiveDisableFeature = (payload) => {
  store.dispatch(setFeatureDisabledAction(payload));
};

const updateMediaProgress = ({ currentTime, endTime }) => {
  store.dispatch(sra.setMediaProgressAction({ currentTime, endTime }));
};

const startPollFunc = (payload) => {
  store.dispatch(spa.startPollAction(payload));
  store.dispatch(spa.resetFocusedPollIdAction());
};

const endPollFunc = (payload) => {
  store.dispatch(spa.endPollAction(payload));
};

const updatePollFunc = (payload) => {
  store.dispatch(spa.updatePollAction({ pollId: payload.pollId, data: payload.data }));
};

const newPollFunc = (payload) => {
  store.dispatch(spa.addPollAction(payload));
};

const pollAnswersFunc = (payload) => {
  store.dispatch(spa.processPollUpdatesAction(payload));
};

const reconnectingUserFunc = (payload) => {
  console.log('Recieved Reconnecting Users Websocket message for:', payload.userId);
  store.dispatch(
    ca.addReconnectingUserAction({
      userId: payload.userId,
      userName: payload.userName,
      timeStamp: payload.timeStamp,
    })
  );
};

const stopReconnectingUserFunc = (payload) => {
  console.log('Recieved Reconnecting Users Websocket message for:', payload.userId);
  store.dispatch(
    ca.removeReconnectingUserAction({
      userId: payload.userId,
    })
  );
};

const setMediaTime = ({ seconds }) => {
  store.dispatch(sra.setVideoTimeSetAction({ videoTimeSet: seconds }));
};
const eventBridgeMeetingEndedFunc = () => {
  store.dispatch(sa.eventBridgeMeetingEndedAction());
};

const updateStageAttendeeFunc = ({ userId, value }) => {
  store.dispatch(sa.updateStageAttendeeAction({ userId, value }));
};

const updateStageAttendeesFunc = ({ value }) => {
  store.dispatch(sa.updateStageAttendeesAction({ value }));
};

const updateStageAttendeeAttrFunc = ({ userId, key, value }) => {
  store.dispatch(sa.handleUpdateStageAttendeeAction({ userId, key, value }));
};

const removeStageAttendeeFunc = (payload) => {
  store.dispatch(sa.removeAttendeeSpeaker(payload));
};

const deletePollFunc = ({ pollId, template }) => {
  store.dispatch(spa.removePollAction({ pollId, template }));
};

const recieveMessageHandlers = {
  'analytics-update': analyticsUpdateFunc,
  'disable-feature': receiveDisableFeature,
  'delete-poll': deletePollFunc,
  'end-session': endSessionFunc,
  'end-poll': endPollFunc,
  'event-bridge-meeting-ended': eventBridgeMeetingEndedFunc,
  'force-logout': forceLogout,
  'group-message': receiveChatFunc,
  'host-started-meeting': hostStartedMeetingFunc,
  'kick-user': kickUserFunc,
  'load-test-request': loadTestRequestFunc,
  'local-video-status': localVideoStatusFunc,
  'logged-in-elsewhere': loggedInElsewhereFunc,
  'media-time-update': updateMediaProgress,
  'mute-all': muteAll,
  'new-poll': newPollFunc,
  'permissions-update-group': permissionsUpdateGroupFunc,
  'poll-answers': pollAnswersFunc,
  'presenters-message': receivePresentersChatFunc,
  'private-message': receiveChatFunc,
  'put-question': receivePutQuestionFunc,
  'queue-message': receiveQueueChatFunc,
  'raise-hand': raiseHandFunc,
  'recording-status': setIsRecordingFunc,
  'remove-all-users-grid': removeAllUsersFromGridFunc,
  'remove-stage-attendee': removeStageAttendeeFunc,
  'select-speaker': selectSpeakerFunc,
  'session-update': updateSessionFunc,
  'set-in-grid': setInGridFunc,
  'start-poll': startPollFunc,
  'stop-user-video': receiveStopUserVideoFunc,
  'stream-status': updateResourceStatusFunc,
  'studio-next-input': studioNextInputFunc,
  'switch-input': switchInputFunc,
  'update-live-stream-user': updateLiveStreamUserFunc,
  'update-poll': updatePollFunc,
  'update-question': updateQuestionFunc,
  'update-stream-status': updateStreamStatusFunc,
  'update-stage-attendee': updateStageAttendeeFunc,
  'update-stage-attendees': updateStageAttendeesFunc,
  'update-stage-attendee-attribute': updateStageAttendeeAttrFunc,
  'user-count': throttle(updateCountFunc, 500),
  reconnecting: reconnectingUserFunc,
  'stop-reconnecting': stopReconnectingUserFunc,
  'set-media-time': setMediaTime,
  mute: receiveMuteFunc,
};

export default function WebSocketInitialization() {
  const dispatch = useDispatch();
  const [hasInitialized, setHasInitialized] = useState(false);
  const socket = useSelector((store) => store.webSocket.socket);

  useEffect(() => {
    if (socket && !hasInitialized) {
      console.log('running');
      socket.addEventListener('open', async (params) => {
        logger.info('The socket has been opened', params);
        dispatch(sa.setIsWebsocketConnectedAction({ connected: true }));
        const { session } = await getSession();
        dispatch(sa.setSessionAction({ ...session }));
      });

      socket.addEventListener('message', function (event) {
        try {
          const { type, payload } = JSON.parse(event.data);
          const wsFunc = recieveMessageHandlers[type];
          if (wsFunc) {
            wsFunc(payload);
          } else {
            console.error('No websocket function found for message %s', type);
          }
        } catch (e) {
          Sentry.captureException(e);
          logger.error('Error in websockets message event', e);
        }
      });

      socket.addEventListener('close', function (event) {
        try {
          dispatch(disconnectUserAction({ code: event.code }));
          dispatch(sa.setIsWebsocketConnectedAction({ connected: false }));
        } catch (e) {
          logger.error('Error disconnecting websockets', e);
        }
      });

      setHasInitialized(true);
    }

    dispatch(initializeWebSocketAction());

    return () => {
      if (socket) {
        try {
          socket.close(3511);
        } catch (error) {
          Sentry.captureException(error);
          logger.error('Error closing socket');
        }
      }

      setHasInitialized(false);
    };
  }, [socket]); //eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
